.container {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: center;
}

.logo-container {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.logo {
  width: 200px;
}

img {
  max-width: 100%;
  max-height: 100%;
}

.home-button {
  background-color: #96bf32;
  color: white;
  width: 80px;
  height: 40px;
  border: 0;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  margin-top: 15px;
  cursor: pointer;
}

.contact-support-link {
  color: #749917;
  text-decoration: none;
}
